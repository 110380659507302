<template>
  <div class="querySelect">
    <h1>{{ msg }}</h1>
    <div class="container">
      <div class="row">
        <div class="col-3">
          ชนิดหวย :
          <select
            ref="lotterySelected"
            v-model="lottery"
            @input="getLotterySelected"
          >
            <option
              v-for="lottery in lotteries"
              :key="lottery"
              :value="lottery"
              >{{ lottery }}</option
            >
          </select>
        </div>

        <div class="col-3">
          รอบ :
          <select
            ref="getRoundSelected"
            v-model="round"
            @input="getRoundSelected"
            :disabled="lottery === ''"
          >
            <option v-for="round in rounds" :key="round" :value="round">{{
              round
            }}</option>
          </select>
        </div>

        <div class="col-3">
          Bet Option :
          <select
            ref="getBetOptionSelected"
            v-model="bet_option_selected"
            @input="getBetOptionSelected"
            :disabled="round === ''"
          >
            <option
              v-for="bet_option in bet_options"
              :key="bet_option"
              :value="bet_option"
              >{{ bet_option }}</option
            >
          </select>
        </div>
      </div>
    </div>
  </div>
  <BetConfigTable v-if="showTable" />
</template>

<script>
import BetConfigTable from '../Table/BetConfigTable.vue'
export default {
  el: "querySelect",
  components:{
  BetConfigTable
  },
  data: function() {
    return {
      lottery: "",
      lotteries: ["หวยรัฐบาลไทย", "หวยลาว", "หวยธกส."],
      round: "",
      rounds: ["รอบ 1 มิ.ย. 2021", "รอบ 16 มิ.ย. 2021", "รอบ 1 ก.ค. 2021"],
      bet_option_selected: "",
      bet_options: ["teng_bon_2", "teng_lang_2", "teng_bon_3", "teng_lang_3"],
      showTable: false
    };
  },
  props: {
    msg: String,
  },
  methods: {
    getLotterySelected() {
      if (this.$refs.lotterySelected.value !== '') {
        this.lottery = this.$refs.lotterySelected.value;
        this.round = "";
        this.bet_option_selected = "";
        this.showTable = false;
      }
    },
    getRoundSelected() {
      if (this.$refs.getRoundSelected.value !== '') {
        this.round = this.$refs.getRoundSelected.value;
        this.bet_option_selected = "";
        this.showTable = false;
      }
    },
    getBetOptionSelected() {
      if (this.$refs.getBetOptionSelected.value !== '') {
        this.bet_option_selected = this.$refs.getBetOptionSelected.value;
        this.getBetConfig();
      }
    },
    getBetConfig() {
      if (
        this.lottery !== '' &&
        this.round !== '' &&
        this.bet_option_selected !== ''
      ) {
        console.log(
          "Get the bet config of ",
          this.lottery,
          ", ",
          this.round,
          " and ",
          this.bet_option_selected
        );
        this.showTable = true;
      }
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
