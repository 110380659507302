<template>
    <QuerySelect msg="แก้ไขอัตราคูณเลขอั้น" />
</template>

<script>
import QuerySelect from "./QuerySelect.vue";

export default {
  name: "Body",
  components: {
    QuerySelect,
  },
};
</script>
