<template>
  <div class="container">
    <DataTable
      :header-fields="headerFields"
      :sort-field="sortField"
      :sort="sort"
      :data="data || []"
      :is-loading="isLoading"
      :css="datatableCss"
      not-found-msg="Items not found"
      @on-update="dtUpdateSort"
      track-by="id"
    >
      <!-- Action button slot -->
      <button
        name="actions"
        type="button"
        class="btn btn-info"
        value="Edit"
        @click="dtEditClick(props)"
      ></button>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import orderBy from "lodash.orderby";

const formatRRStep = (value) => {
  if (value) return "";
};
const editId = (value) => {
  if (value) {
    return value;
  }
};

const initialData = [
  {
    id: 1,
    nums: "11",
    bet_range: "1 - 500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 90, 4000: 45, 9000: 0 },
    status: "active",
  },
  {
    id: 2,
    nums: "12",
    bet_range: "1 - 500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 90, 4000: 45, 9000: 0 },
    status: "active",
  },
  {
    id: 3,
    nums: "44",
    bet_range: "10 - 1500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 90, 4000: 45, 9000: 0 },
    status: "active",
  },
  {
    id: 4,
    nums: "33",
    bet_range: "1 - 500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 80, 4000: 0 },
    status: "active",
  },
  {
    id: 5,
    nums: "23",
    bet_range: "1 - 500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 80, 4000: 0 },
    status: "disabled",
  },
  {
    id: 6,
    nums: "99,98",
    bet_range: "1 - 500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 80, 4000: 0 },
    status: "disabled",
  },
  {
    id: 7,
    nums: "49,94",
    bet_range: "1 - 500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 80, 4000: 0 },
    status: "disabled",
  },
  {
    id: 8,
    nums: "55",
    bet_range: "1 - 500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 80, 4000: 0 },
    status: "disabled",
  },
  {
    id: 9,
    nums: "66",
    bet_range: "1 - 500",
    maxBetPerCust: "1000",
    bet_max: "3000",
    reward_step: { 0: 80, 4000: 0 },
    status: "disabled",
  },
];

export default {
  name: "BetConfigTable",
  components: {
    DataTable,
  },
  data: function() {
    return {
      headerFields: [
        // '__slot:checkboxes',
        {
          name: "id",
          label: "uniqid",
          sortable: true,
        },
        {
          name: "nums",
          label: "เลข",
          sortable: true,
        },
        {
          name: "bet_range",
          label: "ช่วงราคาที่แทงได้",
          //   customElement: 'HometownNew'
        },
        {
          name: "maxBetPerCust",
          label: "แทงได้มากสุดต่อคน",
        },
        {
          name: "bet_max",
          //   customHeader: 'createdHeader',
          label: "แทงได้มากสุดต่อรอบ",
          //   format: formatDate
        },
        {
          name: "reward_step",
          label: "การจ่ายรางวัล",
          //   customHeader: true,
          test: formatRRStep,
        },
        {
          name: "status",
          label: "สถานะ",
          //   customHeader: true,
          sortable: true,
          //   format: formatDate
        },
        {
          name: "id",
          label: "แก้ไข",
          //   customHeader: true,
          //   sortable: true,
          edit: editId,
        },
        // '__slot:actions'
      ],
      data: initialData.slice(0, 10),
      datatableCss: {
        table: "table table-bordered table-hover table-striped table-center",
        theadTr: "header-item",
        theadTh: "custom-thead-th",
        thWrapper: "th-wrapper",
        thWrapperCheckboxes: "th-wrapper checkboxes",
        arrowsWrapper: "arrows-wrapper",
        arrowUp: "arrow up",
        arrowDown: "arrow down",
        footer: "footer",
      },
      paginationCss: {
        paginationItem: "pagination-item",
        moveFirstPage: "move-first-page",
        movePreviousPage: "move-previous-page",
        moveNextPage: "move-next-page",
        moveLastPage: "move-last-page",
        pageBtn: "page-btn",
      },
      isLoading: false,
      sort: "asc",
      sortField: "id",
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 16,
      //   hometown: null
    };
  },
  methods: {
    dtEditClick: (props) => alert(`Click props: ${JSON.stringify(props)}`),

    dtUpdateSort: function({ sortField, sort }) {
      const sortedData = orderBy(initialData, [sortField], [sort]);
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      this.data = sortedData.slice(start, end);
    },

    updateItemsPerPage: function(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      if (itemsPerPage >= initialData.length) {
        this.data = initialData;
      } else {
        this.data = initialData.slice(0, itemsPerPage);
      }
    },

    changePage: function(currentPage) {
      this.currentPage = currentPage;
      const start = (currentPage - 1) * this.itemsPerPage;
      const end = currentPage * this.itemsPerPage;
      this.data = initialData.slice(start, end);
    },

    updateCurrentPage: function(currentPage) {
      this.currentPage = currentPage;
    },

    actionFirstClick: (params) => {
      alert(JSON.stringify(params));
    },
  },
};
</script>
