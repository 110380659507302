<template>
  <div id="app">
    <Header />
    <Body />
  </div>
</template>

<script>
import Body from "@/components/Body/Body.vue";
import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
    Body,
  },
};
</script>
